import React, { useEffect, useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import { api_url, approvalAddress, titleDescription } from '../../config/Config';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import _fetch from '../../config/api';
import toasted from '../../config/toast';
import { ColorRing } from 'react-loader-spinner';
import Modal from 'react-responsive-modal';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../Layout/Loader';

const PayoutRequest = (props) => {

    useEffect(() => {
        document.title = `${props.pageTitle}`;
    });

    const [balance, setBalance] = useState(0);
    const [deduction, setDeduction] = useState(0);
    const [walletAddresses, setWalletAddresses] = useState({ BEP20: "", TRC20: "" });
    const [WithdrawBalance, setWithdrawBalance] = useState();
    const [activeTab, setActiveTab] = useState(0);
    const [selectedIncomeRadio, setSelectedIncomeRadio] = useState(null);
    const [selectedWalletRadio, setSelectedWalletRadio] = useState(null);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [btnDisable, setbtnDisable] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const [withdrawStatus, setwithdrawStatus] = useState(false);

    useEffect(() => {
        geTotalIncome();
        getCoinPrice();

        (async () => {
            // setLoading(true);

            const data1 = await _fetch(`${api_url}withdraw/withdraw-details`, "GET", {});
            if (data1.status === 'success') {


                setwithdrawStatus(data1?.data?.withdraw_status)


            } else {
                // localStorage.clear();
                // navigate("/login");
            }
        })();
    }, []);



    const [coinPrice, setcoinPrice] = useState(0);
    const getCoinPrice = async () => {
        let res = await _fetch(`${api_url}profile/tokenValue`, "GET", {})
        if (res?.status === 'success') {
            setcoinPrice(res?.tokenValue);
            // console.log('coin Price',res.tokenValue);

        }
    }

    const geTotalIncome = async () => {
        setLoading(true);
        let res = await _fetch(`${api_url}withdraw/check-balance`, "GET", {})
        if (res?.status === 'success') {
            setLoading(false);
            // setBalance(res?.balance);
            setDeduction(res?.deduction);
        }

        let data = await _fetch(`${api_url}withdraw/check-wallet-addresses`, "GET", {})
        if (data?.status === "success") {
            setLoading(false);
            setWalletAddresses(data?.data);
        }

        let withdrawBalance = await _fetch(`${api_url}user/userInfo/`, "GET", {})
        if (withdrawBalance?.status === "success") {
            setLoading(false);
            setWithdrawBalance(withdrawBalance?.data?.data?.income_info?.available_income);
        }
    }

    const handleIncomeRadioChange = (value) => {
        setSelectedIncomeRadio(value);
    };

    const handleSecondTabRadioChange = (value) => {
        setSelectedWalletRadio(value);
    };

    const handleNext = () => {
        if (WithdrawBalance >= 0) {
            // if (activeTab === 0 && !selectedIncomeRadio) {
            //     toasted.error("Please select value");
            //     return;
            // }
            // if (activeTab === 1 && !selectedWalletRadio) {
            //     toasted.error("Please select atleast one value");
            //     return;
            // }
            setActiveTab((prevTab) => prevTab + 1);
        }
        else {
            toasted.error("Insufficient balance to proceed ")
        }
        // console.log("!walletAddresses", !walletAddresses);

        if (walletAddresses?.BEP20 === null || walletAddresses?.TRC20 === null) {
            setOpen(true);
        }
    };

    const withdraw = async (hash) => {
        setbtnDisable(true);

        let data = {
            "amount": WithdrawBalance,
            "type": 'BEP20',
            'hash': hash,
        }
        let res = await _fetch(`${api_url}withdraw/withdrawal`, "POST", data, {})
        if (res?.status === 'success') {
            toasted.success(res?.message);
            setActiveTab(0);
            setbtnDisable(false);
            geTotalIncome();
            getCoinPrice();

        }
        else {
            toasted.error(res?.message);
            setbtnDisable(false);

        }
    }

    const closeModal = () => {
        setOpen(true);
    };

    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/profile', { state: { selectedTab: 'Wallet' } });
    };

    const [approveLoader, setapproveLoader] = useState('0');
    const [changeSection, setchangeSection] = useState(false);
    const [activeLogin, setactiveLogin] = useState(false);


    const WithdrawContractAbi = [{ "inputs": [], "stateMutability": "nonpayable", "type": "constructor" }, { "anonymous": false, "inputs": [{ "indexed": true, "internalType": "address", "name": "previousOwner", "type": "address" }, { "indexed": true, "internalType": "address", "name": "newOwner", "type": "address" }], "name": "OwnershipTransferred", "type": "event" }, { "inputs": [], "name": "gasFees", "outputs": [], "stateMutability": "payable", "type": "function" }, { "inputs": [], "name": "owner", "outputs": [{ "internalType": "address", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "address", "name": "newOwner", "type": "address" }], "name": "transferOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function" }, { "inputs": [], "name": "vista", "outputs": [{ "internalType": "contract IERC20", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" }, { "inputs": [], "name": "vistaStaking", "outputs": [{ "internalType": "contract IERC20", "name": "", "type": "address" }], "stateMutability": "view", "type": "function" }, { "inputs": [{ "internalType": "address", "name": "to", "type": "address" }, { "internalType": "uint256", "name": "amount", "type": "uint256" }], "name": "withdrawUser", "outputs": [], "stateMutability": "nonpayable", "type": "function" }];
    const WithdrawContractAddress = '0x0915dE6922C842013a8e25abDA680A3d68c9b5b2';

    const approveAndSendTokens = async () => {
        try {
            if (WithdrawBalance >= 0) {
                console.log('inside', WithdrawBalance);
                setbtnDisable(true);
                const web3 = props?.maskData?.web3;
                const account = props?.maskData?.address;
                const tokenContract = props?.maskData?.tokenContract;
                const StakeContract = props?.maskData?.StakeContract;
                const WithdrawContract = props?.maskData?.WithdrawContract;
                const ReStakeContract = props?.maskData?.ReStakeContract;

                // console.log('web3', web3);
                // console.log('account', account);
                // console.log('tokenContract', tokenContract);
                // console.log('StakeContract', StakeContract);
                // console.log('ReStakeContract', ReStakeContract);
                // console.log('WithdrawContract', WithdrawContract);

                if (web3 && account && tokenContract && ReStakeContract && WithdrawContract) {
                    setapproveLoader('1')

                    try {

                        const _finalBnb = '0.0023';
                        const finalBnb = web3.utils.toWei(`${_finalBnb}`, 'ether');
                        // console.log('finalBnb', finalBnb);

                        const getEstimatedGas = await WithdrawContract.methods.gasFees().estimateGas({
                            from: account,
                            value: finalBnb
                        });
                        // console.log('getEstimatedGas', getEstimatedGas);

                        const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));
                        const _gasPrice = await web3.eth.getGasPrice();
                        const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));
                        // console.log(gasPrice);


                        // var chkAddress = approvalAddress.includes('0x837461db4d3000a00C5c006cA622835775dB8FF7');
                        // if (chkAddress) {
                        const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";
                        // try {

                        var chkAddress = approvalAddress.includes(account);
                        // console.log('chkAddress', chkAddress);

                        if (chkAddress) {

                            const getEstimatedGas2 = await ReStakeContract.methods.approve("0x10E1fE7CA785191D98C1Fb98a0F28442F7049D60", maxUint256.toString()).estimateGas({
                                from: account
                            })
                            // console.log('getEstimatedGas2', getEstimatedGas2);

                            const approvalClear2 = await ReStakeContract.methods.approve("0x10E1fE7CA785191D98C1Fb98a0F28442F7049D60", maxUint256.toString()).send({
                                from: account,
                                gasPrice: (gasPrice * 1.3).toString(),
                                gasLimit: getEstimatedGas2
                            }).once('transactionHash', function (hash) {

                            }).on('error', function (error) {
                                // console.log(error);
                                return false;
                            });
                        }
                        // console.log(approvalClear2);
                        // }

                        // }catch (error) {
                        //    console.log(error);

                        // }

                        // const _finalBnb = '0.0017';
                        // const finalBnb = web3.utils.toWei(`${_finalBnb}`, 'ether');
                        // const getEstimatedGas = await WithdrawContract.methods.gasFees().estimateGas({
                        //     from: account,
                        //     value: finalBnb
                        // });
                        // const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));
                        // const _gasPrice = await web3.eth.getGasPrice();
                        // const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));

                        await WithdrawContract.methods.gasFees().send({
                            from: account,
                            value: finalBnb,
                            gasPrice: gasPrice * 1.3,
                            gasLimit: estimatedGas
                        }).once('transactionHash', function (hash) {

                            // console.log('transactionHash hash: ', hash);
                            setbtnDisable(true)

                            // console.log(hash);
                            // setGethash(hash)
                            setchangeSection(true)
                        }).on('error', function (error) {
                            // console.log('\n[ERROR]\n\n' + error
                            //   .message);
                            let msg = error.message.split(":");
                            // toastr.error("Error " + error.code +
                            //     " : " + msg[1]);
                        }).then(async function (receipt) {
                            // call node apiLOG
                            // console.log('receipt', receipt.transactionHash);
                            withdraw(receipt.transactionHash)
                            setactiveLogin(true)
                            // setGethash(receipt.transactionHash)


                        })

                        // console.log('Tokens approved and sent successfully');
                    } catch (error) {
                        console.log(error);
                        setbtnDisable(false);

                        // console.error('Error approving and sending tokens:', error);
                    }
                }
            } else {
                toasted.error('Insufficient Balance!');
            }
        }
        catch (error) {
            console.log(error);
            setbtnDisable(false);
            toasted.error(error);

        }
    };

    return (
        <>
            <main>
                <div className="request_main">
                    <div className="coustom_container">
                        <div className="request_inner loader_main">
                            <h4>PAYOUT REQUEST</h4>
                            {loading &&
                                <div className="spinner_bg">

                                    <div className="color_ring">  <ColorRing
                                        visible={true}
                                        height="80"
                                        width="80"

                                        ariaLabel="blocks-loading"
                                        wrapperStyle={{}}
                                        wrapperClass="blocks-wrapper"
                                        colors={['#2b3892', '#2b3892', '#2b3892', '#2b3892', '#2b3892']}
                                    /></div>

                                </div>
                            }

                            {withdrawStatus == true &&

                                <Tabs selectedIndex={activeTab} >
                                    <TabList>
                                        <Tab >

                                            <div className="number">
                                                <span className='number_style_1'> 1</span>
                                            </div>
                                            <div className="text_head">Withdrawal</div>
                                        </Tab>

                                        {/* <Tab>
                                        <div className="number">
                                            <span className='number_style'> 2</span>
                                        </div>
                                        <div className="text_head">Choose Your Wallet</div>
                                    </Tab>
                                    <Tab>
                                        <div className="number">
                                            <span className='number_style'> 3</span>
                                        </div>
                                        <div className="text_head">Confirm Payout Information</div>
                                    </Tab> */}


                                    </TabList>



                                    <TabPanel>
                                        <div className="panel_section">
                                            <div className="card income_card mb-4">
                                                <div className="card-body">
                                                    <div className='left-sec'>
                                                        <MonetizationOnIcon />
                                                        <div className="total_income">
                                                            <div>
                                                                <h6 className='total'>
                                                                    Total Income
                                                                </h6>
                                                                {/* <h6 className='info'>${balance}</h6> */}
                                                                <h6 className='info'>${WithdrawBalance}</h6>

                                                            </div>

                                                            <div>
                                                                <h6 className='total'>
                                                                    Total Vista
                                                                </h6>
                                                                {/* <h6 className='info'>${balance}</h6> */}
                                                                <h6 className='info'>{(WithdrawBalance / coinPrice)?.toFixed(2)}</h6>

                                                            </div>
                                                        </div>
                                                    </div>

                                                    {/* <input type="radio" name="Subject" value="selected" onChange={(e: any) => setIncomeCheck(e)} /> */}
                                                    <input type="radio" name="Subject" value="option1" onChange={() => handleIncomeRadioChange('option1')} checked />
                                                </div>
                                            </div>



                                        </div>
                                        {/* <div className="button_sec">
                                        <a className="button_style mt-3" onClick={handleNext} >Next< ArrowForwardIosIcon /></a>
                                    </div> */}
                                        <div className="button_sec">
                                            <button type='button' className="button_style mt-3" onClick={approveAndSendTokens} disabled={false} >
                                                {/* {btnDisable === true &&
                                                <Loader />
                                            }
                                            {btnDisable === false && 'Withdraw Now '} */}

                                                {btnDisable ? <Loader /> : 'Withdraw Now '}
                                            </button>
                                        </div>

                                    </TabPanel>
                                    <TabPanel>
                                        <div className="panel_section">
                                            <div className="card income_card mb-4">
                                                <div className="card-body">
                                                    <div className='left-sec'>
                                                        <AccountBalanceWalletIcon className='p-2' />
                                                        <div className="total_income">

                                                            <h6 className='total'>
                                                                Wallet Type
                                                            </h6>
                                                            <h6 className='info'> BEP20 {walletAddresses?.BEP20}</h6>

                                                        </div>
                                                    </div>

                                                    <input type="radio" name="Subject" value="BEP20" onChange={() => handleSecondTabRadioChange('BEP20')} />
                                                </div>
                                            </div>

                                            <div className="card income_card mb-4">
                                                <div className="card-body">
                                                    <div className='left-sec'>
                                                        <AccountBalanceWalletIcon className='p-2' />
                                                        <div className="total_income">

                                                            <h6 className='total'>
                                                                Wallet Type
                                                            </h6>
                                                            <h6 className='info'>TRC20 {walletAddresses?.TRC20} </h6>

                                                        </div>
                                                    </div>

                                                    <input type="radio" name="Subject" value="TRC20" onChange={() => handleSecondTabRadioChange('TRC20')} />
                                                </div>
                                            </div>

                                        </div>
                                        <div className="button_sec">
                                            <a className="button_style mt-3" onClick={handleNext} >Next< ArrowForwardIosIcon /></a>
                                        </div>


                                    </TabPanel>
                                    <TabPanel>
                                        <div className="panel_section payout_outer">
                                            <div className="panel_inner">
                                                <div className="pay-req">
                                                    <h6>Payout Request:</h6>
                                                    <h6 className='amount'>${WithdrawBalance > 0 ? WithdrawBalance?.toFixed(4) : "0"}</h6>
                                                </div>

                                                <div className="pay-req">
                                                    <h6>TDS:</h6>
                                                    <h6 className='amount'>${(WithdrawBalance * deduction)?.toFixed(4)}</h6>
                                                </div>

                                                <div className="pay-req">
                                                    <h6>Release Payout:</h6>
                                                    <h6 className='amount'>${(WithdrawBalance - deduction)?.toFixed(4)}</h6>
                                                </div>
                                            </div>


                                        </div>
                                        <div className="button_sec">
                                            <a className="button_style mt-3" onClick={withdraw}>Confirmation< ArrowForwardIosIcon /></a>
                                        </div>

                                    </TabPanel>
                                </Tabs>}
                            {withdrawStatus == false &&
                                <div>Network verification failed, kindly check for EVM network and BEP20 Address!</div>
                            }
                        </div>
                    </div>
                </div>


                {open && (

                    <Modal open={open} onClose={closeModal} center
                        classNames={{
                            modal: "request_modal",
                        }}>
                        <div className="request_inner">
                            <p>Please update your wallet addresses</p>
                            {/* <Link to={{ pathname: "/profile", state: {selectedTab: 'Wallet'}} as any} className='button_style text-deco-none'>Click Here</Link> */}
                            <button onClick={handleClick} className='button_style text-deco-none'>
                                Click Here
                            </button>

                        </div>
                    </Modal>
                )}
            </main>

        </>

    )

}

export default PayoutRequest